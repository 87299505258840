import { configureStore, createSlice } from "@reduxjs/toolkit";

const boolSlice = createSlice({
    name:"bool",
    initialState : {
        bool:true,isLogin:false
    },
    reducers : {
        modifier : (state,action)=>{
           state.bool = !state.bool
        }
    }
})
const loginSlice = createSlice({
    name:"login",
    initialState : {
        isLogin:false
    },
    reducers : {
        modifier : (state,action)=>{
           state.isLogin = action.payload
        }
    }
})



export const store = configureStore({
    reducer:{
        bool : boolSlice.reducer,
        login : loginSlice.reducer
    }
})