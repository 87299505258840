import React, { useState, useEffect } from "react";
// import logoKolos from "../assets/images/Logo.svg";
import logoKolos from "../assets/images/Logo_Kolos.svg";
import list from "../assets/images/list.svg";
import closeMenu from "../assets/images/closeMenu.svg";
import { useNavigate } from "react-router-dom";
import mentionLegalPdf from "../assets/pdf/mentions-legales_kolos.pdf";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const MentionLegales = () => {
  const navigate = useNavigate();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  var prevScrollPos = window.pageYOffset;
  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollPos > currentScrollPos) {
      document.querySelector(".conteneurNavigation").style.top = "0";
    } else {
      document.querySelector(".conteneurNavigation").style.top = "-100px";
    }
    prevScrollPos = currentScrollPos;
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      {/* Bar de navigation */}
      <div className="conteneurNavigation">
        <div className="leftItem">
          <span>
            {" "}
            <a
              onClick={(ev) => {
                scrollToTop();
                navigate("/#kolos");
              }}
            >
              <img src={logoKolos} alt="" />
            </a>{" "}
          </span>
          <span className="solution">
            <a
              onClick={(ev) => {
                navigate("/#solution");
              }}
            >
              Solution
            </a>
          </span>
          <span className="contact">
            <a
              onClick={(ev) => {
                navigate("/#contact");
              }}
            >
              Contact
            </a>
          </span>
          <span className="temoignage">
            <a
              href="#temoignage"
              onClick={(ev) => {
                const cible = document.querySelector(".containerNavMobile");
                cible.style = "margin-left:-100vh";
                navigate("#temoignage");
              }}
            >
              Références
            </a>
          </span>
        </div>
        <div className="rightItem">
          <span className="seConnecter">
            <a href="https://app.kolos.fr/" target="_blank">
              Se connecter
            </a>
          </span>
          <a
            href="https://calendly.com/palata_sooda/45min"
            target="_blank"
            style={{ color: "white" }}
            className="buttonKolos"
          >
            Demander une démo
          </a>
          <span
            className="listIcon"
            onClick={(ev) => {
              ev.preventDefault();
              const cible = document.querySelector(".containerNavMobile");
              cible.style = "margin-left:0";
            }}
          >
            <img
              src={list}
              alt=""
              style={{ width: "30px", marginLeft: "20px" }}
            />
          </span>
        </div>
      </div>
      {/* contenu nav Mobile */}
      <div className="containerNavMobile">
        <div className="headNavMobile">
          <img src={logoKolos} alt="" />
          <img
            src={closeMenu}
            alt=""
            style={{ width: "40px" }}
            onClick={(ev) => {
              ev.preventDefault();
              const cible = document.querySelector(".containerNavMobile");
              cible.style = "margin-left:-100vh";
            }}
          />
        </div>
        <div className="bodyNavMobile">
          <span>
            {" "}
            <a
              href="#solution"
              onClick={(ev) => {
                const cible = document.querySelector(".containerNavMobile");
                cible.style = "margin-left:-100vh";
                navigate("/#solution");
              }}
            >
              Solution
            </a>
          </span>
          <span>
            <a
              href="#contact"
              onClick={(ev) => {
                const cible = document.querySelector(".containerNavMobile");
                cible.style = "margin-left:-100vh";
                navigate("/#contact");
              }}
            >
              Contact
            </a>
          </span>
          <span className="temoignage">
            <a
              onClick={(ev) => {
                navigate("/#temoignage");
              }}
            >
              Références
            </a>
          </span>
          <span>
            <a href="https://app.kolos.fr/" target="_blank">
              Se connecter
            </a>
          </span>
          <span>
            <a
              href="https://calendly.com/palata_sooda/45min"
              target="_blank"
              style={{ color: "white" }}
              className="buttonKolos"
            >
              {" "}
              Demander une démo
            </a>
          </span>
        </div>
      </div>

      <div className="container" style={{ marginTop: "100px" }}>
        <div>
          <span className="badgeKolos" style={{width:'56px'}}>KOLOS</span>
          <h2
            className="h2"
            style={{
              fontSize: "40px",
              fontWeight: "700",
              lineHeight: "60px",
              color: "#2a3342",
            }}
          >
            {" "}
            Mention légales
          </h2>
        </div>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
          <div
            style={{
              height: "85vh",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Viewer
              fileUrl={mentionLegalPdf}
              plugins={[defaultLayoutPluginInstance]}
            />
          </div>
        </Worker>
      </div>
      {/* Pied de page */}
      <div
        className="page7"
        id="contact"
        // style={{ display: "flex", alignItems: "center",padding:'50px 0px' }}
      >
        <div className="item2">
          <div className="list">
            <span>
              <img src={logoKolos} alt="" style={{ marginBottom: "12px" }} />
            </span>
            <span className="description">
              Une solution qui simplifie le suivi de vos chantiers
            </span>
          </div>
          <div className="list">
            <span className="titre">Contact</span>
            <span className="description">
              6 rue des deux communes 91480 QUINCY SOUS SENART
            </span>
            <span className="description">contact@kolos.fr</span>
            <span className="description">06.89.16.06.93</span>
          </div>
          <div className="list">
            <span className="titre">Solution</span>

            <a
              href="https://calendly.com/palata_sooda/45min"
              target="_blank"
              className="buttonKolos"
              style={{ color: "white", textAlign: "center", maxWidth: "200px" }}
            >
              Demander une démo
            </a>
          </div>
          <div className="list">
            <span className="titre">Informations légales</span>
            <span
              className="description"
              style={{ cursor: "pointer" }}
              onClick={(ev) => {
                scrollToTop();
                navigate("/mention_légales");
              }}
            >
              Mentions légales
            </span>
            <span
              className="description"
              style={{ cursor: "pointer" }}
              onClick={(ev) => {
                scrollToTop();
                navigate("/confidentialité");
              }}
            >
              Politique de confidentialité
            </span>
            <span
              className="description"
              style={{ cursor: "pointer" }}
              onClick={(ev) => {
                scrollToTop();
                navigate("/CGV");
              }}
            >
              CGV
            </span>
          </div>
        </div>
        <div className="marque">
          Marques déposées : © 2023 SOODA SAS - © 2023 KOLOS - Tous droits
          réservés.
        </div>
      </div>
    </>
  );
};

export default MentionLegales;
