import React, { useEffect, useState, useRef } from "react";
import logoKolos from "../assets/images/Logo_Kolos.svg";
import list from "../assets/images/list.svg";
import logoBlue from "../assets/images/Logo blue.svg";
import image1 from "../assets/images/imagePage1.png";
import image1Page2 from "../assets/images/Image1Page2.svg";
import image2Page2 from "../assets/images/image2Page2.png";
import checked from "../assets/images/Vector.svg";
import listIcon from "../assets/images/listIcon.svg";
import closeMenu from "../assets/images/closeMenu.svg";
import pilotageIcon from "../assets/images/pilotageIcon.svg";
import coordinationIcon from "../assets/images/coordinationIcon.svg";
import visuelSolution from "../assets/images/Visuel Solution.svg";
import suiviOp from "../assets/images/suiviOp.svg";
import suiviPlanning from "../assets/images/suiviPlanning.svg";
import tableauDeBord from "../assets/images/tableauDeBord.svg";
import securiteIconSvg from "../assets/images/securiteIcon.svg";
import checkIconSvg from "../assets/images/check.svg";
import rapport from "../assets/images/rapport.svg";
import tableauDeBordPage4 from "../assets/images/TableauDeBordPage4.png";
import suiviPlanningPage4 from "../assets/images/suiviPlanningPage4.png";
import suiviOpPage4 from "../assets/images/suiviOpPage4.png";
import suiviOpMobile from "../assets/images/suiviOpMobile.png";
import rapportPage4 from "../assets/images/rapportPage4.png";
import rapportMobile from "../assets/images/rapportMobile.png";
import ref1 from "../assets/images/egis-vector-logo-removebg-previe.png";
import ref2 from "../assets/images/Logo_BMPI.png";
import ref3 from "../assets/images/logo-dégradation-bleu.png";
import ref5 from "../assets/images/logo-sgp_0.png";
import ref4 from "../assets/images/paris2024-removebg-preview (1).png";
import "../assets/css/Home.scss";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import html from "../pages/mail";
import htmlAdmin from "./mailToAdmin";
import emailjs from "@emailjs/browser"
//import nodemailer from 'nodemailer';

const Home = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  var prevScrollPos = window.pageYOffset;
  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollPos > currentScrollPos) {
      document.querySelector(".conteneurNavigation").style.top = "0";
    } else {
      document.querySelector(".conteneurNavigation").style.top = "-100px";
    }
    prevScrollPos = currentScrollPos;
  };
  const [item, setItem] = useState("tableauDeBord");
  const [showModal, setShowModal] = useState(false);
  const [tailleEcran, setTailleEcran] = useState(window.innerWidth);
  const [societe, setSociete] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleResize = () => {
    setTailleEcran(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sendMail = () => {
    console.log(formRef.current)
    // const subject = "KOLOS - Logiciel dédié au pilotage de chantier";
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        formRef.current,  
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        () => {
          addToast("Envoyé avec succès", {
            appearance: "success",
            autoDismiss: "true",
          });
          console.log("SUCCESS!");

        },
        (error) => {
          alert('erreur');
          console.log(error);
        }
      );

      
    // axios
    //   .post(`${process.env.REACT_APP_URL}/api/v1/mails/send`, {
    //     html: html,
    //     objectsToReplace: [
    //       {
    //         identifiant: "{prenom}",
    //         replaceTo: prenom,
    //       },
    //     ],
    //     object: subject,
    //     destinataire: email,
    //   })
    //   .then((res) => {
    //     console.log(res);
    //     addToast("Votre demande a été envoyée avec succès.", {
    //       appearance: "success",
    //       autoDismiss: true,
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     addToast(
    //       "Une erreur s'est produite, veuillez réessayer dans un instant.",
    //       {
    //         appearance: "error",
    //         autoDismiss: true,
    //       }
    //     );
    //   });
    // axios
    //   .post(`${process.env.REACT_APP_URL}/api/v1/mails/send`, {
    //     html: htmlAdmin,
    //     objectsToReplace: [
    //       {
    //         identifiant: "{prenom}",
    //         replaceTo: prenom,
    //       },
    //       {
    //         identifiant: "{nom}",
    //         replaceTo: nom,
    //       },
    //       {
    //         identifiant: "{societe}",
    //         replaceTo: societe,
    //       },
    //       {
    //         identifiant: "{email}",
    //         replaceTo: email,
    //       },
    //       {
    //         identifiant: "{tel}",
    //         replaceTo: tel,
    //       },
    //     ],
    //     object: "Demande d'un client",
    //     destinataire: process.env.REACT_APP_EMAIL_ADMIN,
    //   })
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  useEffect(() => {
    if (tailleEcran <= 1000) {
      setItem("allItem");
    } else {
      setItem("tableauDeBord");
    }
  }, [tailleEcran]);



  const formRef = useRef()







  return (
    <>
      {/* Bar de navigation */}
      <div className="conteneurNavigation">
        <div className="leftItem">
          <span>
            {" "}
            <a href="#kolos">
              <img src={logoKolos} alt="" />
            </a>{" "}
          </span>
          <span className="solution">
            <a href="#solution">Solution</a>
          </span>
          <span className="contact">
            <a href="#contact">Contact</a>
          </span>
          <span className="temoignage">
            <a href="#temoignage">Références</a>
          </span>
        </div>
        <div className="rightItem">
          <span className="seConnecter">
            <a href="https://app.kolos.fr/" target="_blank">
              Se connecter
            </a>
          </span>
          <a
            href="https://calendly.com/palata_sooda/45min"
            target="_blank"
            style={{ color: "white" }}
            className="buttonKolos"
          >
            Demandez une démo
          </a>
          <span
            className="listIcon"
            onClick={(ev) => {
              ev.preventDefault();
              const cible = document.querySelector(".containerNavMobile");
              cible.style = "margin-left:0";
            }}
          >
            <img
              src={list}
              alt=""
              style={{ width: "30px", marginLeft: "20px" }}
            />
          </span>
        </div>
      </div>

      {/* contenu nav Mobile */}
      <div className="containerNavMobile">
        <div className="headNavMobile">
          <img src={logoKolos} alt="" />
          <img
            src={closeMenu}
            alt=""
            style={{ width: "40px" }}
            onClick={(ev) => {
              ev.preventDefault();
              const cible = document.querySelector(".containerNavMobile");
              cible.style = "margin-left:-100vh";
            }}
          />
        </div>
        <div className="bodyNavMobile">
          <span>
            {" "}
            <a
              href="#solution"
              onClick={(ev) => {
                const cible = document.querySelector(".containerNavMobile");
                cible.style = "margin-left:-100vh";
                navigate("#solution");
              }}
            >
              Solution
            </a>
          </span>
          <span>
            <a
              href="#contact"
              onClick={(ev) => {
                const cible = document.querySelector(".containerNavMobile");
                cible.style = "margin-left:-100vh";
                navigate("#contact");
              }}
            >
              Contact
            </a>
          </span>
          <span className="temoignage">
            <a
              href="#temoignage"
              onClick={(ev) => {
                const cible = document.querySelector(".containerNavMobile");
                cible.style = "margin-left:-100vh";
                navigate("#temoignage");
              }}
            >
              Références
            </a>
          </span>
          <span>
            <a href="https://app.kolos.fr/" target="_blank">
              Se connecter
            </a>
          </span>
          <span>
            <a
              href="https://calendly.com/palata_sooda/45min"
              target="_blank"
              style={{ color: "white" }}
              className="buttonKolos"
            >
              {" "}
              Demandez une démo
            </a>
          </span>
        </div>
      </div>

      {/* Page numéro 1 */}
      <div className="page1" id="kolos">
        <div className="leftItem">
          <h1 className="h1" style={{ color: "#2A3342" }}>
            La solution qui simplifie le suivi de vos chantiers
          </h1>{" "}
          <br />
          <span className="h2">
            <span style={{ marginBottom: "20px" }}>
              Kolos, une mémoire d’éléphant pour une efficacité redoutable.{" "}
            </span>{" "}
            <br /> <br />
            Grâce à notre solution, gagnez du temps et en efficacité.
          </span>
          <a
            // href="#En_savoir_plus"
            href="#solution"
            className="buttonKolos"
            style={{
              width: "191px",
              textAlign: "center",
              marginTop: "25px",
              color: "white",
            }}
          >
            En savoir plus
          </a>
        </div>
        <div className="rightItem">
          <img src={image1} alt="" className="image1" />
        </div>
      </div>

      {/* Page numéro 2 */}
      <div className="page2" id="section2">
        <div className="headerPage2">
          <div className="firstItem">
            {" "}
            {/*  <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span className="opcPage2">MOEx</span>
              <span className="opcPage2">OPC</span>
              <span className="opcPage2">Ingénieur Travaux</span>
            </div> */}
            <h1 className="h1" style={{paddingBottom:'20px'}} >
              La solution créée sur mesure pour le suivi de chantier{" "}
            </h1>
           {/*  <h2 className="h2">un rôle d’interface très sollicité</h2> */}
            <div className="listIcons">
              <div>
                <span className="buttonKolos">
                  <img src={checkIconSvg} alt="" />
                </span>
                <h3 style={{ marginTop: "10px" }}>Conformité</h3>
              </div>
              <div>
                <span className="buttonKolos">
                  <img src={listIcon} alt="" />
                </span>
                <h3 style={{ marginTop: "10px" }}>Supervision</h3>
              </div>
              <div>
                <span className="buttonKolos">
                  <img src={pilotageIcon} alt="" />
                </span>
                <h3 style={{ marginTop: "10px" }}>Pilotage</h3>
              </div>
              <div>
                <span className="buttonKolos">
                  <img src={coordinationIcon} alt="" />
                </span>
                <h3 style={{ marginTop: "10px" }}>Coordination</h3>
              </div>
              <div>
                <span className="buttonKolos">
                  <img src={securiteIconSvg} alt="" />
                </span>
                <h3 style={{ marginTop: "10px" }}>Sécurité</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="secondItem">
          <div className="partie1">
            <img src={image1Page2} alt="" className="image1Page2" />
            <img src={image2Page2} alt="" className="image2Page2" />
          </div>
          <div className="partie2">
            <span>La solution conçue pour répondre :</span>
            <div>
              <span>
                <span className="iconChecked">
                  {" "}
                  <img src={checked} alt="" />{" "}
                </span>
                <span>
                  Difficulté à suivre plusieurs chantiers en simultanée
                </span>
              </span>
              <span>
                <span className="iconChecked">
                  {" "}
                  <img src={checked} alt="" />{" "}
                </span>
                <span>
                  Rédaction chronophage et diffusion des comptes-rendus de
                  réunion
                </span>
              </span>
              <span>
                <span className="iconChecked">
                  {" "}
                  <img src={checked} alt="" />{" "}
                </span>
                <span>Problématique de partage d'informations du chantier</span>
              </span>
              <span>
                <span className="iconChecked">
                  {" "}
                  <img src={checked} alt="" />{" "}
                </span>
                <span>Absence de justification des retards</span>
              </span>
              <span>
                <span className="iconChecked">
                  {" "}
                  <img src={checked} alt="" />{" "}
                </span>
                <span>Multiplication des supports utilisés</span>
              </span>
             
            </div>
          </div>
        </div>
      </div>

      {/* Page numéro 3 */}
      <div className="page3" id="solution">
        <div className="leftItem">
          <span className="badgeKolos">KOLOS</span>
          <h2 className="h2"> La solution pour faciliter votre quotidien</h2>
         
          <div>
            <span
              className="buttonKolos"
              onClick={(ev) => {
                ev.preventDefault();
                setShowModal(true);
              }}
            >
              Demandez la plaquette commerciale
            </span>
          </div>
        </div>
        <div className="rightItem">
          <img src={visuelSolution} alt="" />
        </div>
      </div>

      {/* Ancien page 4 */}
      {/*  <div className="page4" id="En_savoir_plus">
        <div className="leftItem">
          <div className="containerImage">
            {item == "tableauDeBord" && (
              <img
                src={tableauDeBordPage4}
                alt=""
                className="image"
                style={{ width: "115%" }}
              />
            )}
            {item == "suiviPlanning" && (
              <img
                src={suiviPlanningPage4}
                alt=""
                className="image"
                style={{ width: "90%" }}
              />
            )}
            {item == "suiviOperationnel" && (
              <img
                src={suiviOpMobile}
                alt=""
                className="image"
                style={{ width: "100%" }}
              />
            )}
            {item == "rapport" && (
              <img
                src={rapportMobile}
                alt=""
                className="image"
                style={{ width: "85%" }}
              />
            )}
          </div>
        </div>
        <div className="rightItem">
          <div className="headerPage4">
            <span className="badgeKolos">Application</span>
            <h2 className="h1">
              Un logiciel dédié au suivi de chantier et adapté à tous vos
              déplacements
            </h2>
            <span className="h2">
              Pour maîtriser au mieux l’exécution du chantier et la coordination
              entre acteurs. Kolos a été pensé et conçu comme un outil d’aide
              pour atteindre ces objectifs. Son périmètre se limite donc au
              strict pilotage dans la phase opérationnelle et s’étend dans la
              durée contractuelle de la mission.
            </span>
          </div>
          <div
            className="item"
            style={
              item == "tableauDeBord" ? { borderLeft: "3px solid #6366F1" } : {}
            }
            onClick={(ev) => {
              ev.preventDefault();
              tailleEcran > 1000 && setItem("tableauDeBord");
            }}
          >
            <span>
              {" "}
              <img src={tableauDeBord} alt="" />{" "}
            </span>
            <span className="info">
              <h3
                className="titre"
                style={{ padding: "0", margin: "0", marginLeft: "14px" }}
              >
                Tableau de bord
              </h3>
              <span
                className="description"
                style={
                  item == "tableauDeBord" || "allItem"
                    ? {}
                    : { display: "none" }
                }
              >
                Dès l’attribution d’un projet, accédez à votre espace où que
                vous soyez, avec une connexion internet et créez votre chantier
                sur le logiciel. Il ne reste plus qu’à renseigner les
                informations du chantier.
              </span>
            </span>
          </div>
          <img
            src={tableauDeBordPage4}
            alt=""
            className="image"
            style={{ width: "115%" }}
          />
          <div
            className="item"
            style={
              item == "suiviPlanning" ? { borderLeft: "3px solid #6366F1" } : {}
            }
            onClick={(ev) => {
              ev.preventDefault();
              tailleEcran > 1000 && setItem("suiviPlanning");
            }}
          >
            <span>
              {" "}
              <img
                src={suiviPlanning}
                alt=""
                style={{ width: "38px", height: "38px" }}
              />{" "}
            </span>
            <span className="info">
              <h3
                className="titre"
                style={{ padding: "0", margin: "0", marginLeft: "14px" }}
              >
                Suivi planning
              </h3>
              <span
                className="description"
                style={
                  item == "suiviPlanning" || "allItem"
                    ? {}
                    : { display: "none" }
                }
              >
                Assurez un suivi à la loupe des différentes tâches, comparez le
                prévu, le planifié et le réalisé, ordonnez l'enchaînement des
                tâches et les incompatibilités d’un simple geste.
              </span>
            </span>
          </div>
          <img
            src={suiviPlanningPage4}
            alt=""
            className="image"
            style={{ width: "90%" }}
          />
          <div
            className="item"
            style={
              item == "suiviOperationnel"
                ? { borderLeft: "3px solid #6366F1" }
                : {}
            }
            onClick={(ev) => {
              ev.preventDefault();
              tailleEcran > 1000 && setItem("suiviOperationnel");
            }}
          >
            <span>
              {" "}
              <img
                src={suiviOp}
                alt=""
                style={{ width: "38px", height: "38px" }}
              />{" "}
            </span>
            <span className="info">
              <h3
                className="titre"
                style={{ padding: "0", margin: "0", marginLeft: "14px" }}
              >
                Suivi opérationnel
              </h3>
              <span
                className="description"
                style={
                  item == "suiviOperationnel" || "allItem"
                    ? {}
                    : { display: "none" }
                }
              >
                Anticipez l’ensemble des évènements par une liste d’actions
                proposées par l’application et notez la fiabilité/satisfaction
                des entreprises.
              </span>
            </span>
          </div>
          <img
            src={suiviOpMobile}
            alt=""
            className="image"
            style={{ width: "90%" }}
          />
          <div
            className="item"
            style={item == "rapport" ? { borderLeft: "3px solid #6366F1" } : {}}
            onClick={(ev) => {
              ev.preventDefault();
              tailleEcran > 1000 && setItem("rapport");
            }}
          >
            <span className="info">
              {" "}
              <img src={rapport} alt="" />{" "}
            </span>
            <span className="info">
              <h3
                className="titre"
                style={{ padding: "0", margin: "0", marginLeft: "14px" }}
              >
                Rapports optimisés
              </h3>
              <span
                className="description"
                style={
                  item == "rapport" || "allItem" ? {} : { display: "none" }
                }
              >
                Générer des rapports de visite et des comptes-rendus détaillés
                en cliquant et cochant des critères disponibles sur l’outil.
              </span>
            </span>
          </div>
          <img
            src={rapportMobile}
            alt=""
            className="image"
            style={{ width: "90%" }}
          />
        </div>
      </div> */}
      <div className="page4" id="En_savoir_plus">
        <div className="page4Container">
          <div
            className="item"
            onClick={(ev) => {
              ev.preventDefault();
              tailleEcran > 1000 && setItem("tableauDeBord");
            }}
          >
            <span>
              {" "}
              <img src={tableauDeBord} alt="" />{" "}
            </span>
            <span className="info">
              <h3
                className="titre"
                style={{ padding: "0", margin: "0", marginLeft: "14px" }}
              >
                Tableau de bord
              </h3>
              <span
                className="description"
                style={
                  item == "tableauDeBord" || "allItem"
                    ? {}
                    : { display: "none" }
                }
              >
                Dès l’attribution d’un projet, accédez à votre espace où que
                vous soyez, avec une connexion internet et créez votre chantier
                sur la solution. Il ne reste plus qu’à renseigner les
                informations du chantier.
              </span>
            </span>
          </div>
          <img src={tableauDeBordPage4} alt="" className="image" />
        </div>
        <div className="page4Container reverse">
          <img
            src={suiviPlanningPage4}
            alt=""
            className="image"
            style={{ padding: "0px 5%" }}
          />
          <div
            className="item"
            onClick={(ev) => {
              ev.preventDefault();
              tailleEcran > 1000 && setItem("suiviPlanning");
            }}
          >
            <span>
              {" "}
              <img
                src={suiviPlanning}
                alt=""
                style={{ width: "38px", height: "38px" }}
              />{" "}
            </span>
            <span className="info">
              <h3
                className="titre"
                style={{ padding: "0", margin: "0", marginLeft: "14px" }}
              >
                Suivi planning
              </h3>
              <span
                className="description"
                style={
                  item == "suiviPlanning" || "allItem"
                    ? {}
                    : { display: "none" }
                }
              >
                Assurez un suivi à la loupe des différentes tâches, comparez le
                prévu, le planifié et le réalisé, ordonnez l'enchaînement des
                tâches et les incompatibilités d’un simple geste.
              </span>
            </span>
          </div>
        </div>
        <div className="page4Container">
          <div
            className="item"
            style={
              item == "suiviOperationnel"
                ? { borderLeft: "3px solid #6366F1" }
                : {}
            }
            onClick={(ev) => {
              ev.preventDefault();
              tailleEcran > 1000 && setItem("suiviOperationnel");
            }}
          >
            <span>
              {" "}
              <img
                src={suiviOp}
                alt=""
                style={{ width: "38px", height: "38px" }}
              />{" "}
            </span>
            <span className="info">
              <h3
                className="titre"
                style={{ padding: "0", margin: "0", marginLeft: "14px" }}
              >
                Suivi opérationnel
              </h3>
              <span
                className="description"
                style={
                  item == "suiviOperationnel" || "allItem"
                    ? {}
                    : { display: "none" }
                }
              >
                Anticipez l’ensemble des évènements par une liste d’actions
                proposées par KOLOS et notez la fiabilité/satisfaction
                des entreprises.
              </span>
            </span>
          </div>
          <img src={suiviOpMobile} alt="" className="image" />
        </div>
        <div className="page4Container reverse">
          <img
            src={rapportMobile}
            alt=""
            className="image"
            style={{ padding: "0 5%" }}
          />
          <div
            className="item"
            style={item == "rapport" ? { borderLeft: "3px solid #6366F1" } : {}}
            onClick={(ev) => {
              ev.preventDefault();
              tailleEcran > 1000 && setItem("rapport");
            }}
          >
            <span className="info">
              {" "}
              <img src={rapport} alt="" />{" "}
            </span>
            <span className="info">
              <h3
                className="titre"
                style={{ padding: "0", margin: "0", marginLeft: "14px" }}
              >
                Rapports optimisés
              </h3>
              <span
                className="description"
                style={
                  item == "rapport" || "allItem" ? {} : { display: "none" }
                }
              >
                Générez des rapports de visite et des comptes-rendus détaillés
                en cliquant et cochant des critères disponibles sur la solution.
              </span>
            </span>
          </div>
        </div>
      </div>

      {/* Page numéro 5 */}
      <div className="page5" id="section5">
        <div className="zone1">
          <h2 className="h1">Trouvez la solution adaptée à vos besoins</h2>
          <h3 className="h2">
            Une solution en ligne dédiée au suivi de chantier connectée à la
            réalité et au quotidien des pilotes de chantier.
          </h3>
          <a
            href="https://calendly.com/palata_sooda/45min"
            target="_blank"
            style={{ color: "white" }}
            className="buttonKolos"
          >
            {" "}
            Demandez une démo
          </a>
        </div>
        <div className="info">
          <span className="badgeKolos">LA PROPOSITION DE VALEUR</span>
          <h2 className="h1">Renforcez votre leadership sur site</h2>
          <h3 className="h2">
            Arrêtez de perdre du temps avec des tâches à faible valeur ajoutée.
          </h3>
          <div className="items">
            <div className="item">
              <span className="nb1">1</span>
              <span className="nb2">Facilitez</span>
              <span className="nb3"> le quotidien</span>
            </div>
            <div className="item">
              <span className="nb1">2</span>
              <span className="nb2">Améliorez</span>
              <span className="nb3">l'efficacité</span>
            </div>
            <div className="item">
              <span className="nb1">3</span>
              <span className="nb2">Augmentez</span>
              <span className="nb3">le volume</span>
            </div>
          </div>
          <span className="info2">
            Notre mission est de vous faire gagner un temps précieux dans la
            gestion de vos projets. Grâce à notre solution, vous serez plus
            efficace et vous pourrez consacrer d'avantage de temps pour échanger
            avec vos collaborateurs ou améliorer votre qualité de suivi. Et
            pourquoi pas, accroître votre volume d’affaires par la capacité à
            absorber d’autres missions.
            <br />
            {/* Il est donc essentiel pour nous de vous former au mieux et de vous
            accompagner dans l’utilisation pour parvenir à une parfaite maîtrise
            de notre logiciel.
            <br />
            Si vous voulez en savoir plus, prenez rendez-vous avec un de nos
            conseillers au 06 89 16 06 93 */}
          </span>
        </div>
      </div>


      {/* Section Témoignage Vidéo */}
      {/* <div className="info">
          <span className="icon">LA PROPOSITION DE VALEUR</span>
          <h2 className="h1">Renforcez votre leadership sur site</h2>
          <h3 className="h2">
            Arrêtez de perdre du temps avec des tâches à faible valeur ajoutée.
          </h3> */}
      <div className="pageTemoignage" id="temoignage">
        <span className="badgeKolosTemoignage">Références</span>
        <div className="temoignageContainer">
          <h2 className="titreTemoignage">Ce que nos utilisateurs disent de nous</h2>
          <div className="temoignageContent">
            <div className="temoignageVideo">
              <iframe 
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/O7RFe4rPCWs?si=jiL59jsm_Ct7QbxX" 
                title="Témoignage utilisateur"
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                // referrerpolicy="strict-origin-when-cross-origin" 
                allowfullscreen
              ></iframe>
            </div>
            <div className="temoignageText">
              <p>
                <i>"Avant, je passais trois à quatre heures pour obtenir le compte rendu final avec les fiches de visites. Maintenant, j'arrive à <strong>diviser ce temps par deux</strong> grâce à KOLOS."</i>
              </p>
              <p>- Jérémy Desbrugères, Chef de projet MOEx / OPC</p>
            </div>
          </div>
          <div className="listItem">
            <img src={ref4} alt="" style={{ height: "80px" }} />
            <img src={ref5} alt="" style={{ height: "75px" }} />
            <img src={ref3} alt="" style={{ height: "80px" }} />
            <img src={ref1} alt="" style={{ width: "150px", height: "auto" }} />
            <img src={ref2} alt="" style={{ height: "90px" }} />
          </div>
        </div>
      </div>

    {/* RÉFÉRENCES */}
    {/* <div className="listReference">
      <span>Références</span>
      <div className="listItem">
        <img src={ref4} alt="" style={{ height: "80px" }} />
        <img src={ref5} alt="" style={{ height: "80px" }} />
        <img src={ref3} alt="" style={{ height: "80px" }} />
        <img src={ref1} alt="" style={{ width: "220px", height: "auto" }} />
        <img src={ref2} alt="" />
      </div>
    </div> */}


      {/* Section en savoir plus */}
      <div className="page6" id="contact">
        <div className="item1">
          <div className="part1">
            <span>
              <img src={logoBlue} alt="" />
            </span>
            <h2 className="h1">Pour en savoir plus</h2>
            <span className="h2">
              Vous souhaitez avoir plus de renseignements pour être plus
              efficace quotidiennement ? <br /> La solution peut être mise en
              place quelque soit l'état d'avancement d'un projet.
            </span>
          </div>
          <div className="part2">
            <span
              className="buttonKolos"
              onClick={(ev) => {
                ev.preventDefault();
                setShowModal(true);
              }}
            >
              Contactez nos équipes
            </span>
          </div>
        </div>
        <div className="item2">
          <div className="list">
            <span>
              <img src={logoKolos} alt="" style={{ marginBottom: "12px" }} />
            </span>
            <span className="description">
              Une solution qui simplifie le suivi de vos chantiers
            </span>
          </div>
          <div className="list">
            <span className="titre">Contact</span>
            <span className="description">
              6 rue des deux communes 91480 QUINCY SOUS SENART
            </span>
            <span className="description">contact@kolos.fr</span>
            <span className="description">06.89.16.06.93</span>
          </div>
          <div className="list">
            <span className="titre">Solution</span>

            <a
              href="https://calendly.com/palata_sooda/45min"
              target="_blank"
              className="buttonKolos"
              style={{ color: "white", textAlign: "center", maxWidth: "200px" }}
            >
              Demandez une démo
            </a>
          </div>
          <div className="list">
            <span className="titre">Informations légales</span>
            <span
              className="description"
              style={{ cursor: "pointer" }}
              onClick={(ev) => {
                scrollToTop();
                navigate("/mention_légales");
              }}
            >
              Mentions légales
            </span>
            <span
              className="description"
              style={{ cursor: "pointer" }}
              onClick={(ev) => {
                scrollToTop();
                navigate("/confidentialité");
              }}
            >
              Politique de confidentialité
            </span>
            <span
              className="description"
              style={{ cursor: "pointer" }}
              onClick={(ev) => {
                scrollToTop();
                navigate("/CGV");
              }}
            >
              CGV
            </span>
          </div>
        </div>
        <div className="marque">
          Marques déposées : © KOLOS - Tous droits
          réservés.
        </div>
      </div>

      {/* Modal de la plaquette commerciale */}
      {showModal && (
        <>
          <form
            onSubmit={(ev) => {
              ev.preventDefault();
              sendMail();
              ev.target.reset();
              setShowModal(false);
            }}
            ref={formRef}
            

          >
            <div className="modalPlaquette">
              <div className="containerPlaquette">
                <div className="headerPlaquette">
                  Demandez notre plaquette commerciale
                </div>
                <div className="bodyPlaquette">
                  <div class="mb-3 mt-3">
                    <label for="email" class="form-label">
                      Société<span>*</span>:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="email"
                      placeholder="Société*"
                      name="societe"
                      onChange={(ev) => {
                        ev.preventDefault();
                        setSociete(ev.target.value);
                      }}
                      required
                    />
                  </div>
                  <div class="mb-3 mt-3">
                    <label for="email" class="form-label">
                      Nom<span>*</span>:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="text"
                      placeholder="Nom*"
                      name="nom"
                      onChange={(ev) => {
                        ev.preventDefault();
                        setNom(ev.target.value);
                      }}
                      required
                    />
                  </div>
                  <div class="mb-3 mt-3">
                    <label for="email" class="form-label">
                      Prénom<span>*</span>:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="prenom"
                      placeholder="Prénom*"
                      name="prenom"
                      onChange={(ev) => {
                        ev.preventDefault();
                        setPrenom(ev.target.value);
                      }}
                      required
                    />
                  </div>
                  <div class="mb-3 mt-3">
                    <label for="email" class="form-label">
                      Email<span>*</span>:
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="Email*"
                      name="email"
                      onChange={(ev) => {
                        ev.preventDefault();
                        setEmail(ev.target.value);
                      }}
                      required
                    />
                  </div>
                  <div class="mb-3 mt-3">
                    <label for="email" class="form-label">
                      Téléphone<span>*</span>:
                    </label>
                    <input
                      type="phone"
                      class="form-control"
                      id="phone"
                      placeholder="Téléphone*"
                      name="phone"
                      onChange={(ev) => {
                        ev.preventDefault();
                        setTel(ev.target.value);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="footerPlaquette">
                  <span
                    className="buttonCloseKolos"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setShowModal(false);
                    }}
                  >
                    Fermer
                  </span>
                  <button
                    type="submit"
                    className="buttonKolos"
                    style={{ maxWidth: "200px", border: "none" }}
                  >
                    Envoyer
                  </button>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default Home;
