import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import Home from "./pages/Home";
import BienTot from "./pages/BienTot";
import MentionLegales from "./pages/MentionLegales";
import Confidentialite from "./pages/Confidentialité";
import CGV from "./pages/CGV";
import { store } from "./redux";
import { TaostProvider, ToastProvider } from "react-toast-notifications";
function App() {
  return (
    <ToastProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/mention_légales" element={<MentionLegales />} />
            <Route path="/confidentialité" element={<Confidentialite />} />
            <Route path="/CGV" element={<CGV />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ToastProvider>
  );
}

export default App;
